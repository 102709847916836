/* .pac-container:after{display:none !important;} */

.pac-container:after {
  background-image: none !important;
  height: 0px;
  padding: 0;
  margin: 0;
  box-shadow: none;
}
.pac-container {
  box-shadow: none;
  border: 1px solid #c1c1c1;
  border-radius: 8px;
  margin-top: 5px;
  padding: 10px;
}
.pac-icon {
  display: none;
}
.pac-item {
  border: none;
  color: #919191;
}
.pac-matched {
  background-color: #fff;
  color: #484848;
}
.pac-item-query {
  background-color: #eaf5fe;
  color: #2269ad;
}
