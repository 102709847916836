@keyframes bounce-once {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px); /* Adjust the bounce height */
  }
}

.animate-bounce-once {
  animation: bounce-once 1s 1;
}